@tailwind base;
@tailwind utilities;

html {
    --light-code: #888;
    --span-bg: rgb(240, 240, 240);
    --span-var-hover-bg: #ddd;
    --span-var-active-bg: rgb(191 219 254); /* blue-200 */
    --code-font: 'Fira Code', monospace;
    --fg: #0F172A;
    --bg: white;
}

body {
    color: var(--fg);
    background: var(--bg);
    user-select: none;
}

.cm-editor .cm-scroller {
    line-height: 1.6;
}

.cm-editor .cm-content {
    font-family: var(--code-font);
    font-size: 14px;
}

.readonly .cm-activeLine, .readonly .cm-activeLineGutter {
    background: transparent !important;
}

.cm-editor .cm-gutters {
    background: none;
    border: none;
}

.ͼ4 .cm-line {
    display: flex;
    flex-direction: row;
}

input {
    background: none;
}

code, .code {
    font-family: var(--code-font);
}

/* Spans */

.span-default {
    flex-grow: 1;
}
.span-var {
    background: var(--span-bg);
    cursor: default;
}
.span-var:hover {
    background: var(--span-var-hover-bg)
}
.span-var-active, .span-var-active:hover {
    background: var(--span-var-active-bg);
}
.span-value {
    color: var(--light-code);
    flex-grow: 1;
    text-align: right;
}
.span-literal {
    pointer-events: auto;
    cursor: ew-resize;

    text-decoration-line: underline;
    text-decoration-style: dotted;
    text-decoration-thickness: 2px;
    text-underline-offset: 3px;
    text-decoration-color: #999;
}
.span-literal.span-literal-active {
    text-decoration-color: black;
}

/* Dark mode */

@media (prefers-color-scheme: dark) {
    html {
        --fg: white;
        --bg: black;
        --span-bg: rgb(61, 64, 71);
        --span-var-hover-bg: rgb(81, 84, 91);
        --span-var-active-bg: rgb(37 99 235); /* blue-600 */
    }
    .span-var-active, .span-var-active:hover {
        color: white;
    }
}
